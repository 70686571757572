/**
 * These thumbnails will load a spip template that will look for thumbnails in
 * different places (enclosures or img in the content of a feed entry, URL of an
 * image, meta or img elements in the page linked by the URL)
 */
class ThumbnailAsync {
    /**
     * Define a thumbnail loaded asynchronously.
     *
     * @param {string} node - DOM node
     */
    constructor ( node ) {
        this.node = node;
        this.thumbnailId = this.node.getAttribute( 'data-thumbnail-id' );
        this.source = this.node.getAttribute( 'data-thumbnail-source' );
        this.isLoadingCheck = 500;
    }

    /**
     * Fetch content from the Spip template.
     *
     * @return {Promise} - Fetching the thumbnail from a custom PHP script.
     */
    getThumbnail () {
        return fetch( `/?page=thumbnail_from_${ this.source }&id=${ this.thumbnailId }` );
    }

    /**
     * Handle the loading process.
     *
     * @return {Promise} - getThumbnail, proxied
     */
    load () {
        // Add class on the element loading.
        this.node.classList.add( 'is-loading' );

        // Load thumbnail.
        const isLoading = new Promise( resolve => {
            resolve( this.getThumbnail() );
        } );

        // Handle responses of the promises.
        return isLoading
            .then( data => {
                // Replace content if the request is deemed OK.
                if ( data.ok ) {
                    data.text().then( text => {
                        // Create a temporary container to create a DOM structure based on the
                        // string of the response. Then get the first image within this response.
                        const domContainer = document.createElement( 'i' );

                        domContainer.innerHTML = text.trim();

                        const [ img ] = domContainer.getElementsByTagName( 'img' );

                        // Wait for the image to be loaded before replacing the initial thumbnail.
                        // This will avoid having the content jumping all over the place.
                        img.addEventListener( 'load', () => {
                            this.node.innerHTML = text;
                        } );
                    } );
                }

                // Remove class on loaded elements, with a delay to make it nicer.
                setTimeout( () => {
                    this.node.classList.remove( 'is-loading' );
                }, this.isLoadingCheck );
            } )
            .catch();
    }
}

/**
 * Gallery of thumbnails
 */
class Gallery {
    /**
     * Define a Gallery.
     *
     * @param {string} gallery            - DOM node
     * @param {number} concurrentRequests - Number of concurrent requests for this gallery.
     */
    constructor ( gallery, concurrentRequests ) {
        this.counter = 0;
        this.gallery = gallery;
        this.thumbnails = [ ...gallery.querySelectorAll( '.thumbnail--async' ) ];
        this.nextItem = 0;
        this.concurrentRequests = concurrentRequests;
    }

    /**
     * Load a batch of thumbnails from this gallery.
     *
     * @param {number} startIndex - First index to load in this batch of thumbnails
     *
     * @return {undefined}
     */
    loadThumbnails ( startIndex ) {
        this.thumbnails
            .slice( startIndex, this.concurrentRequests )
            .forEach( ( thumbnail, index ) => {
                this.loadThumbnail( index );
            } );
    }

    /**
     * Load a Thumbnail in a Gallery.
     *
     * @param {number} index - index of the thumbnail to load within the current gallery
     *
     * @return {undefined}
     */
    loadThumbnail ( index ) {
        const thumbnail = this.thumbnails[ index ];

        new ThumbnailAsync( thumbnail, index )
            .load()

            // Load the next item on the list, based on the initial number of requests.
            .then( () => {
                const nextItem = index + this.concurrentRequests;
                const nextNode = this.thumbnails[ nextItem ];

                if ( nextNode ) {
                    this.loadThumbnail( nextItem );
                }
            } );
    }
}

// Load all the thumbnails only after the window is fully loaded.
window.addEventListener( 'load', () => {
    'use strict';

    // Find all galleries.
    // Limit the number of simultaneous requests per page
    // 6 is Firefox’s default: https://developer.mozilla.org/docs/Tools/Network_Monitor
    const galleries          = document.querySelectorAll( '.gallery' );
    const galleriesNumber    = galleries.length;
    const startAtImage       = 0;
    const concurrentRequests = 6;
    const requestsPerGallery = concurrentRequests > galleriesNumber + 1
        ? Math.floor( concurrentRequests / galleriesNumber )
        : 1;

    galleries.forEach( gallery => {
        new Gallery( gallery, requestsPerGallery )
            .loadThumbnails( startAtImage );
    } );
} );

